import React from "react"
import Layout from "../components/Layout"
import { Container, Row, Col } from "react-bootstrap"
// import nc from "../images/nc.jpg"
import * as styles from "../styles/home.module.css"
import { StaticImage } from "gatsby-plugin-image"
import Headmeta from "../components/Headmeta"




export default function about() {
  return (
    <Layout>
      <Headmeta importedIn="about | The team that fills colors in your lives" importedUrl='about' />
      <div>
        <Container className={styles.homeStyles}>
          <Row>
            <Col>
              <h1 className='text-center display-5 pt-5' >Hello, I am...</h1>
              <p>
                We are glad that you want to know about us. Currently, I am the only 
                person having a permanent seat in Coordinarts council which I believe shall change
                soon and we shall have more permanent members. That said! I would like to add that there
                are friends and family members which are always eager to help and without them, I would have to
                struggle a lot to keep Coordinarts alive. {" "}
              </p>
              <p>A part of 'about' section goes to people like you as well. It's because of the opportunities 
                that you give me, I am who I am today. Well, who I am? Read below.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
            <StaticImage
              src="../images/nc.jpg"
              alt="Niharika pic"
              width="200"
              placeholder="blurred"
              className="rounded-circle pt-3"
              loading="eager"
            />
              {/* <img
                src={nc}
                alt="Niharika pic"
                width="200"
                className="rounded-circle pt-3"
              /> */}
            </Col>
            <Col className="d-flex align-items-center pt-3 text-justify justify-content">
              <p>
                Hey, I am Niharika. I am a full time artist. After graduating as
                a lawyer, I contemplated a lot about whether to don the black
                and white clothes or pick colors. Surprise, Surprise! I chose
                the latter. My vision is to make the world a more beautiful place
                and fill people's lives with more colors (literally as well).{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
